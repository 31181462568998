#Dashboard {
    width: 100%;
    height: 100%;
    padding: 0 !important;
}

#Dashboard .component {
    margin-bottom: .3333rem;    

    width: 100%;
    /* height: 33.3333%; */
}

#Dashboard #first-level {
    height: 30%;
}

#Dashboard #second-level {
    height: 35%;
}

#Dashboard #third-level {
    height: 35%;
}

#Dashboard .component:last-child {
    margin: 0;
}

.component .cv-card {
    margin-right: .3333rem;
}

.component .cv-card:last-child {
    margin: 0;
}

.cv-card {
    border: .5px solid rgba(44,51,73,.15);
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 0.2rem .2rem 0 rgba(44,51,73,.1);
}

.cv-card-header {
    padding-top: .1667rem;
    padding-bottom: .1667rem;
    padding-left: .3333rem;
    padding-right: .3333rem;
    /* font-size: .9rem; */
    font-size: .9rem;
    font-weight: 625;
    /* color: var(--secondary); */
    color: var(--primary);
}

#Dashboard .cv-card-body {
    padding: .3333rem;
}

/* TODO: integrate useful media queries */
#Dashboard #first-level .cv-plot-container {
    height: 240px !important;
}

#Dashboard #second-level .cv-plot-container {
    height: 285px !important;
}

#Dashboard #third-level .cv-plot-container {
    height: 285px !important;
}

/*  */
.tooltip {
    line-height: 1rem;    
    font-weight: 500    ;
    font-size: .9rem;
    background-color: var(--primary);
    color: #ffff;
    border-radius: 3px;
    padding: .3333rem .5rem;

    -moz-user-select: none;
    -webkit-user-select: none;
    pointer-events: none;
}

/* TODO: media-queries for responsiveness */
#first-level .cv-card {
    height: 100%;
    width: 50%;
}

#second-level .cv-card {
    height: 100%;
    width: 50%;
}

#third-level .cv-card {
    height: 100%;
    width: 50%;
}