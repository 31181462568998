#Metrics {
	width: 100%;
	height: 100%;
	padding: 0 !important;
}

#Metrics .component {
	width: 100%;
	/* height: 33.3333%; */
}

#Metrics .component .cv-metric-card {
	margin-right: 0.3333rem;
}

#Metrics .component .cv-metric-card:last-child {
	margin: 0;
}

#Metrics .cv-metric-card {
	padding: 0 !important;
	border: 0.5px solid rgba(44, 51, 73, 0.15);
	border-radius: 3px;
	background-color: #ffffff;
	box-shadow: 0 0.2rem 0.2rem 0 rgba(44, 51, 73, 0.1);
	height: 150px;
}

#Metrics .cv-metric-card-header {
	padding-top: 0.1667rem;
	padding-bottom: 0.1667rem;
	padding-left: 0.3333rem;
	padding-right: 0.3333rem;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	background-color: var(--primary);
	font-size: 0.9rem;
	font-weight: 625;
	color: white;
}

#Metrics .cv-metric-card-body {
	padding-top: 0.3333rem;
	font-size: 1.5rem;
	font-weight: 625;
	color: var(--primary);
}

#Metrics .cv-metric-card-footer {
	padding-top: 0.1667rem;
	padding-bottom: 0.1667rem;
	padding-left: 0.3333rem;
	padding-right: 0.3333rem;

	position: absolute;
	bottom: 0;
}
