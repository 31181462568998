:root,
body,
#root {
	/* width: 100%;     */
	/* height: 100%;    */
	width: 100vw;
	height: 100vh;
}

:root {
	font-size: 15px;
	/* TODO: */
	/* font-family: 'Open Sans', 'sans-serif';  */
	--primary: #020b14;
	--primary50: rgba(2, 11, 20, 0.5);
	--primary30: rgba(2, 11, 20, 0.3);
	--primary10: rgba(2, 11, 20, 0.1);
	/* TODO: refactor color scheme, primaryEvent */
	--secondary: #1976d2;
}

body {
	color: var(--primary) !important;
}

/* .App-header */

.App-header {
	padding: 0.3333rem;
	/* position: fixed; */
	/* width: 100%; */
	background-color: var(--primary);
	color: #ffffff;
	font-weight: bold;
	font-size: 1.1rem;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.9), 0 0px 2px rgba(0, 0, 0, 0.9);
	z-index: 400;
}

.nav-collapse-icon {
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	margin-right: 0.3333rem;
	/* border-radius: 3px; */
	border-radius: 1px;
	/* border-radius: 2px; */
}

.nav-collapse-icon:hover,
.App-nav.collapsed .nav-collapse-icon:hover {
	background-color: var(--secondary);
}

.heading,
.sidebar-collapse-icon {
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}

.heading {
}

.sidebar-collapse-icon {
}

/* .App-nav */

.App-nav {
	transition: ease 275ms;
	padding-left: 0.3333rem;
	padding-right: 0.5rem;
	padding-top: 0.6666rem;
	/* TODO: optimize width for 1920*X */
	width: 11rem;
	border-right: 1px solid rgb(0, 0, 0, 0.1);
}

.App-nav.collapsed {
	transition: ease 275ms;
	width: 3rem;
	padding-right: 0.3333rem;
	/* A */
	background-color: var(--primary90);
	/* B */
	background-color: #ffffff;
}

.nav > * {
	padding-top: 0.1rem;
	padding-bottom: 0.1rem;
}

.nav-item a {
	padding: 0.3333rem;
	/* border-radius: 3px; */
	border-radius: 1px;
	/* border-radius: 2px;  */
	font-size: 1.1rem;
	color: var(--primary);
}

.nav-item svg,
.nav-item a {
	font-size: 1.1rem;
	font-weight: 600;
	color: var(--primary);
	text-decoration: none;
}

.nav-item a:hover {
	transition: ease 275ms;
	background-color: var(--primary);
	color: #ffffff;
}

.nav-item a:hover svg {
	transition: ease 275ms;
	color: #ffffff;
}

.App-nav.collapsed .nav-item a svg {
	transition: ease 275ms;
	/* A */
	color: #ffffff;
	/* B */
	color: var(--primary);
}

.App-nav.collapsed .nav-item a:hover {
	transition: ease 275ms;
	/* A */
	background-color: #ffffff;
	/* B */
	background-color: var(--primary);
}

.App-nav.collapsed .nav-item a:hover svg {
	transition: ease 275ms;
	/* A */
	color: var(--primary);
	/* B */
	color: #ffffff;
}

.nav .icon-wrapper,
.nav .icon-label-wrapper {
	height: 1.5rem;
	text-align: center;
	-moz-user-select: none;
	-webkit-user-select: none;
	pointer-events: none;
}

.nav .icon-wrapper {
	width: 1.5rem;
	margin-right: 0.7333rem;
}

.App-nav.collapsed .icon-wrapper {
	margin: 0;
	color: #ffffff;
}

.App-nav.collapsed .icon-label-wrapper {
	display: none;
}

/* .App-main */

.App-main {
	padding: 0.3333rem;
	background-color: #f7f7f7;
}

/* Other stylings */

span {
	-moz-user-select: none;
	-webkit-user-select: none;
	pointer-events: none;
}

ul {
	list-style: none;
}

ul,
li {
	margin: 0;
	padding: 0;
}

/* .App-sidebar */

.App-sidebar {
	transition: ease 275ms;
	padding-left: 0.3333rem;
	padding-right: 0.3333rem;
	padding-top: 0.6666rem;
	width: 5rem;
}

.App-sidebar.collapsed {
	transition: ease 275ms;
	padding: 0;
	padding-top: 0.6666rem;
	width: 0;
}

/* Toast */

div.toast-container {
	position: fixed;
	bottom: 0.3333rem;
	right: 0.3333rem;
	/* width: 17.5rem; */
	width: 20rem;
}

div.cv-toast {
	width: 20rem;
	height: 7.5rem;
}

div.cv-toast > * {
	width: 100%;
}

/* Links */

.disabled-link {
	pointer-events: none;
}
