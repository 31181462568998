#Data {
    width: 100%;
    height: 100%;
    padding: 0 !important;

    /* position: fixed; */
    /* overflow-y: scroll; */
}

#Data .component {
    margin-bottom: .3333rem;

    width: 100%;
    /* height: 33.3333%; */
}

#Data .component:last-child {
    margin: 0;
}

#Data .component .cv-card {
    margin-right: .3333rem;
}

#Data .component .cv-card:last-child {
    margin: 0;
}

#Data .cv-card {
    border: .5px solid rgba(44, 51, 73, .15);
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 0.2rem .2rem 0 rgba(44, 51, 73, .1);
}

#Data .cv-card-header {
    padding-top: .1667rem;
    padding-bottom: .1667rem;
    padding-left: .3333rem;
    padding-right: .3333rem;
    font-size: .9rem;
    font-weight: 600;
    color: var(--secondary);
}

#Data .cv-card-body {
    padding: 1rem;
}

#Data .cv-card-body-component {
    margin-bottom: .6667rem;
}

#Data .cv-card-body-component:last-child {
    margin-bottom: 0;
}

.drop-zone {
    border: .15rem dashed var(--primary50);
    border-radius: 3px;
    padding: 2rem;
}

.drop-zone.active {
    background-color: var(--primary10);
}

.drop-zone>* {
    margin-bottom: .1667rem;
}

.drop-zone span {
    font-weight: 600;
}

#Data button,
#Data input[type=submit] {
    all: unset;
    color: #ffffff;
    background-color: var(--primary);
    border-color: var(--primary);
    border-radius: 3px;
    padding: .3333rem 1rem;
}

#Data button:hover,
#Data input[type=submit]:hover
{
    color: #ffffff;
    background-color: var(--secondary);
    border-color: var(--secondary);
    border-radius: 3px;
}

#Data select,
input[type=text],
input,
span {
    border-radius: 3px;
    padding: .3333rem 1rem;
    border-color: var(--primary30);
    border-width: 1px;
}

#Data select {
    width: 100%;
}

#Data input[type=text] {
    /* border-color: var(--primary); */
}

#Data input:read-only {
    user-select: none;
    pointer-events: none;
    background-color: #ffffff;

}

#Data input[type=file] {
    display: none;
}

#Data .input-group-text {
    background-color: var(--primary10);
    color: var(--primary);
    border-color: var(--primary30);
    border-right: 0px;

    font-weight: 600;
}

#Data label {
    padding: .3333rem 1rem;
    margin: 0 !important;
    font-weight: 600;
}